import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4543b4e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_overlay = _resolveComponent("van-overlay")!

  return (_openBlock(), _createBlock(_component_van_overlay, {
    class: "proc-comps-preview",
    show: _ctx.modelValue,
    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('update:modelValue', false)), ["stop"])),
    onTouchend: _withModifiers(() => {}, ["stop"]),
    onTouchmove: _withModifiers(() => {}, ["stop"])
  }, {
    default: _withCtx(() => [
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "proc-comps-preview-wrapper",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('update:modelValue', false)), ["stop"]))
          }, [
            (_ctx.config.type === 'img')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.config.url,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, _hoisted_1))
              : (_openBlock(), _createElementBlock("iframe", {
                  key: 1,
                  src: _ctx.config.url,
                  frameborder: "0",
                  style: {"background":"#fff"},
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, _hoisted_2))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_van_icon, {
        class: "proc-comps-preview-close",
        name: "cross",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', false)))
      })
    ]),
    _: 1
  }, 8, ["show"]))
}