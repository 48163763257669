
import { defineComponent, toRefs, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ImagePreview } from "vant";
import fetchApi from "@/api";
import { eventBus, getUrlParams } from "@/utils";
import { DetailProps, BusinessInfoItem, BusinessDetailItem, FlowInfoItem } from "./../type";
import TraceItem from "./TraceItem.vue";
import PageHeader from "@/components/PageHeader.vue";
import { DetailInfo, StatusSignet, ListItem } from "./components";
import PreviewFilter from "@/components/PreviewFile/index.vue";
import { contextInject } from "@/config/context";

export default defineComponent({
  name: "Detail",
  components: {
    TraceItem,
    DetailInfo,
    StatusSignet,
    PreviewFilter,
    ListItem,
    PageHeader,
  },
  props: {
    signetType: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
      required: false,
      default: '申请信息',
    },
  },
  setup(props) {
    const router = useRouter();
    const detail = ref<DetailProps>();
    const businessInfo = ref<BusinessInfoItem[]>([]);
    const businessDetail = ref<BusinessDetailItem[]>([]);
    const activeIds = ref<string[]>([]);
    const flowInfo = ref<FlowInfoItem[]>([]);
    const visible = ref(false);
    const previewConfig = ref({ url: "", type: "img" });
    const { loading, updateLoading } = contextInject();
    const search = getUrlParams();
    const decodeOfferData = ref<{ [_: string]: string }>({});
    const activeTab = ref(0); // 响应式变量，用于控制当前激活的标签页

    eventBus.on("decodeOffer", (processInstanceId) => {
      fetchApi
        .getDecodeOffer()
        .data({
          processInstanceId,
        })
        .send("POST")
        .then((res) => {
          if (res && res.data) {
            const data = JSON.parse(res.data);
            decodeOfferData.value = data;
          }
        });
    });
    const getDetailInfo = () => {
      return fetchApi
        .getApproveDetail()
        .params({ pid: search.get("processInstanceId") })
        .send();
    };
    const handleDetailInfo = (res: any) => {
      const data = JSON.parse(res.data);
      detail.value = data;
      console.log(data);
      if (data.mainFormInfo) {
        const { fieldChineseNameMap = {}, fieldValueMap } = data.mainFormInfo;
        Object.keys(fieldChineseNameMap).forEach((key) => {
          if (fieldValueMap[key]) {
            businessInfo.value.push({
              key,
              label: fieldChineseNameMap[key],
              value: fieldValueMap[key],
            });
          }
          if (
            fieldChineseNameMap[key] == "是否高风险" &&
            fieldValueMap[key] == "是"
          ) {
            eventBus.emit("openHighRiskTips");
          }
        });
      }
      if (data.sonFormInfos && data.sonFormInfos.length > 0) {
        businessDetail.value = data.sonFormInfos.map((formInfo: any) => {
          const { fieldChineseNameMap, records } = formInfo;
          if (fieldChineseNameMap && records) {
            const list: BusinessInfoItem[] = records.result.map((item: any) => {
              return Object.keys(fieldChineseNameMap).reduce((res: any, key: string) => {
                if (item[key]) {
                  return [
                    ...res,
                    {
                      key,
                      label: fieldChineseNameMap[key],
                      value: item[key],
                    },
                  ];
                }
                return res;
              }, []);
            });
            return {
              formCode: formInfo.formCode,
              formName: formInfo.formName,
              list,
              total: records.totalCount,
            };
          }
          return {
            formCode: formInfo.formCode,
            formName: formInfo.formName,
            list: [],
          };
        });
      }
    };

    const getApproveTrance = () => {
      return fetchApi
        .getApproveTrace()
        .params({ pid: search.get("processInstanceId") })
        .send();
    };
    const handleApproveTrance = (res: any) => {
      const data = JSON.parse(res.data);
      const { history = [], now = [], future = [] } = data[0];
      flowInfo.value = [
        ...history.map((item: FlowInfoItem) => ({
          ...item,
          position: "history",
        })),
        ...now.map((item: FlowInfoItem) => ({ ...item, position: "now" })),
        ...future.map((item: FlowInfoItem) => ({
          ...item,
          position: "future",
        })),
      ];
      console.log(flowInfo.value);
      eventBus.emit(
        "afterGetNowApprovers",
        now
          .map((item: FlowInfoItem) => item.approver_erp.split(","))
          .reduce((approves: [string[]], erp: string | string[]) => {
            if (Array.isArray(erp)) {
              return [...approves, ...erp];
            }
            return [...approves, erp];
          }, []),
      );
    };
    onMounted(() => {
      updateLoading(true);
      Promise.all([getDetailInfo(), getApproveTrance()])
        .then(([res, res2]) => {
          handleDetailInfo(res);
          handleApproveTrance(res2);
          updateLoading(false);
        })
        .catch(() => {
          updateLoading(false);
        });
    });

    const doPreview = (config: { type: string; url: string }) => {
      if (config.type === "img") {
        ImagePreview({
          images: [config.url],
          closeable: true,
          showIndex: false,
        });
        return;
      }
      previewConfig.value = config;
      visible.value = true;
    };

    const showSpin = (spin: boolean) => {
      loading.value = spin;
    };

    const goDetailList = (formCode: string) => {
      router.push({
        path: "/formInfoDetail",
        query: { formCode, processInstanceId: search.get("processInstanceId") },
      });
    };

    return {
      detail,
      businessInfo,
      businessDetail,
      activeIds,
      flowInfo,
      loading,
      doPreview,
      visible,
      previewConfig,
      showSpin,
      decodeOfferData,
      goDetailList,
      activeTab,
    };
  },
});
