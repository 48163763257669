
import { defineComponent } from "vue";

export default defineComponent({
  name: "Template",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({ type: "img", url: "" }),
    },
  },
  setup() {},
});
