
import { defineComponent, ref, toRefs, computed, PropType } from "vue";
import { ImagePreview } from "vant";
import fetchApi from "@/api";
import AvatarList, {
  AvatarItemProps,
  AvatarItem,
} from "@/components/AvatarList";
import { openContactsCard } from "@/utils";
import { ListItem } from "./components";
import PreviewFilter from "@/components/PreviewFile/index.vue";
import { contextInject } from "@/config/context";
import { ItemValue } from "./../type";

const errorStatus = ["2", "3", "4", "12"];

export default defineComponent({
  name: "TraceItem",
  components: { AvatarList, AvatarItem, ListItem, PreviewFilter },
  props: {
    title: {
      type: String,
      default: "",
    },
    userErp: {
      type: String,
      default: "",
    },
    userName: {
      type: String,
      default: "",
    },
    headImg: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "history",
    },
    approveResult: {
      type: String,
      default: "1",
    },
    type: {
      type: String,
      default: "",
    },
    submitComment: {
      type: String,
      default: "",
    },
    submitTime: {
      type: String,
      default: "",
    },
    taskAttachmentList: {
      type: Array as PropType<ItemValue>,
      default: () => [],
    },
  },
  setup(props) {
    const {
      position,
      approveResult,
      userErp,
      userName,
      headImg,
      type,
      submitComment,
    } = toRefs(props);
    const status = ref("");
    const avatars = ref<AvatarItemProps[]>([]);
    const visible = ref(false);
    const previewVisible = ref(false);
    const previewConfig = ref({ url: "", type: "img" });
    const { updateLoading } = contextInject();

    const description = computed(() => {
      let str = userName.value;
      if (submitComment.value) {
        str += ` 意见${submitComment.value}`;
      }
      return str;
    });

    if (position.value === "history") {
      status.value =
        errorStatus.indexOf(approveResult.value) > -1 ? "error" : "success";
    } else if (position.value === "now") {
      status.value = type.value === "EndEvent" ? "success" : "info";
    } else {
      status.value = "default";
    }

    if (userErp.value) {
      const imgs = headImg.value.split(",");
      userErp.value.split(",").forEach((erp, i) => {
        avatars.value.push({
          avatar: imgs[i],
          erp: userErp.value.split(",")[i],
          name: userName.value.split(",")[i],
        });
      });
    }

    const openCard = (erp: string) => {
      fetchApi
        .getRobotAppId()
        .params({
          pin: erp,
        })
        .silent(true)
        .noHandleResponse()
        .send("GET")
        .then((res: any) => {
          openContactsCard(erp, res.data);
        })
        .catch(() => {
          openContactsCard(erp);
        });
    };

    const touchend = () => {
      if (avatars.value.length === 1) {
        openCard(userErp.value);
      } else {
        visible.value = true;
      }
    };

    const doPreview = (config: { type: string; url: string }) => {
      if (config.type === "img") {
        ImagePreview({
          images: [config.url],
          closeable: true,
          showIndex: false,
        });
        return;
      }
      previewConfig.value = config;
      previewVisible.value = true;
    };

    const showSpin = (spin: boolean) => {
      updateLoading(spin);
    };

    return {
      status,
      avatars,
      visible,
      description,
      touchend,
      openCard,
      previewVisible,
      previewConfig,
      showSpin,
      doPreview,
    };
  },
});
